import { lazy } from 'react';

// project imports
//import MainLayout from 'layout/MainLayout';
import HomeLayout from 'layout/HomeLayout';
import Loadable from 'ui-component/Loadable';

const Channel = Loadable(lazy(() => import('views/Channel')));
const Log = Loadable(lazy(() => import('views/Log')));
const Redemption = Loadable(lazy(() => import('views/Redemption')));
const Setting = Loadable(lazy(() => import('views/Setting')));
const Token = Loadable(lazy(() => import('views/Token')));
const Topup = Loadable(lazy(() => import('views/Topup')));
const User = Loadable(lazy(() => import('views/User')));
const Profile = Loadable(lazy(() => import('views/Profile')));
const NotFoundView = Loadable(lazy(() => import('views/Error')));

// dashboard routing
const Dashboard = Loadable(lazy(() => import('views/Dashboard')));
const ModelSquare = Loadable(lazy(() => import('views/ModelSquare')));
const ModelSquare_new = Loadable(lazy(() => import('views/ModelSquare_new')));
const ModelSquareManage = Loadable(lazy(() => import('views/ModelSquareManage')));
const ModelTagManage = Loadable(lazy(() => import('views/ModelTagManage')));
const ModelDetails = Loadable(lazy(() => import('views/ModelDetails')));
const ExplorationCenterChatBot = Loadable(lazy(() => import('views/ExplorationCenter/ChatBot')));
const StatisticsPanel = Loadable(lazy(() => import('views/StatisticsPanel')));
const StatisticsPanelManage = Loadable(lazy(() => import('views/StatisticsPanelManage')));
const ExplorationCenterText2Img = Loadable(lazy(() => import('views/ExplorationCenter/text2img')));
const ExplorationCenterChatTTS = Loadable(lazy(() => import('views/ExplorationCenter/chattts')));
const ModelFineTuning = Loadable(lazy(() => import('views/ModelFineTuning')));
const ModelFineService = Loadable(lazy(() => import('views/ModelFineService')));
const ModelFineTuningCreate = Loadable(lazy(() => import('views/ModelFineTuning/create')));
const ModelFinetuneDetail = Loadable(lazy(() => import('views/ModelFineTuning/detail')));
const ModelFineServiceCreate = Loadable(lazy(() => import('views/ModelFineService/create')));
const ModelFineServiceDetail = Loadable(lazy(() => import('views/ModelFineService/detail')));
const TeamManage = Loadable(lazy(() => import('views/TeamManage')));
const UsageStatistics = Loadable(lazy(() => import('views/UsageStatistics')));
const TeamUserToken = Loadable(lazy(() => import('views/TeamManage/UserToken')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/panel',
  element: <HomeLayout />,
  children: [
    {
      path: '',
      element: <Dashboard />
    },
    {
      path: 'statistics',
      element: <StatisticsPanel />
    },

    {
      path: 'dashboard',
      element: <Dashboard />
    },
    {
      path: 'modelsquare',
      element: <ModelSquare />
      // element: <ModelSquare_new /> //修改处
    },
    {
      path: 'modelsquare-manage',
      element: <ModelSquareManage />
    },
    {
      path: 'modeltag/manage',
      element: <ModelTagManage />
    },
    {
      path: 'usage/statistics',
      element: <UsageStatistics />
    },
    {
      path: 'user_index_distribution',
      element: <StatisticsPanelManage />
    },
    {
      path: 'exploration_center',
      element: <ExplorationCenterChatBot />
    },
    {
      path: 'exploration_center/text2img',
      element: <ExplorationCenterText2Img />
    },
    {
      path: 'exploration_center/chattts',
      element: <ExplorationCenterChatTTS />
    },
    {
      path: 'modelsquare/details/:id',
      element: <ModelDetails />
    },
    {
      path: 'model/fine_tuning',
      element: <ModelFineTuning />
    },
    {
      path: 'model/fine_tuning/create',
      element: <ModelFineTuningCreate />
    },
    {
      path: 'model/finetune/detail/:id',
      element: <ModelFinetuneDetail />
    },
    {
      path: 'model/fine_service',
      element: <ModelFineService />
    },
    {
      path: 'model/fine_service/create/:id',
      element: <ModelFineServiceCreate />
    },
    {
      path: 'model/fine_service/detail/:id',
      element: <ModelFineServiceDetail />
    },
    {
      path: '/panel/team_manage',
      element: <TeamManage />
    },
    {
      path: '/panel/team_usertoken',
      element: <TeamUserToken />
    },
    {
      path: 'channel',
      element: <Channel />
    },
    {
      path: 'log',
      element: <Log />
    },
    {
      path: 'redemption',
      element: <Redemption />
    },
    {
      path: 'setting',
      element: <Setting />
    },
    {
      path: 'token',
      element: <Token />
    },
    {
      path: 'topup',
      element: <Topup />
    },
    {
      path: 'user',
      element: <User />
    },
    {
      path: 'profile',
      element: <Profile />
    },
    {
      path: '404',
      element: <NotFoundView />
    }
  ]
};

export default MainRoutes;
