import { useRoutes } from 'react-router-dom';
// import { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
// routes
import MainRoutes from './MainRoutes';
import HomeRoutes from './HomeRoutes';
import { OtherRoutes, noMainRoutes } from './OtherRoutes';
import { newRoutes } from './LoginRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  // const location = useLocation();
  const routes = useRoutes([
    MainRoutes,
    // HomeRoutes, //修改处
    OtherRoutes,
    noMainRoutes,
    newRoutes
  ]);

  // // 登录状态检查函数
  // const checkLoginStatus = () => {
  //   // 从 localStorage 中获取用户信息
  //   const user = JSON.parse(localStorage.getItem('user'));
  //   return !!user;
  // };

  // useEffect(() => {
  //   // 在路由切换时检查登录状态
  //   const isLoggedIn = checkLoginStatus();
  //   const chaportDom = document.querySelector('.chaport-container');
  //   // 加载或移除 Chaport 脚本
  //   if (chaportDom) {
  //     chaportDom.style.display = isLoggedIn ? 'block' : 'none';
  //   }
  // }, [location.pathname]); // 在 location.pathname 或 isLoggedIn 改变时触发

  return routes;
}
