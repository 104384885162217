import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import LoginLayout from 'layout/LoginLayout';

const AuthRegisterUser = Loadable(lazy(() => import('views/Authentication/LoginAuth/RegisterUser')));
const AuthRegisterEmail = Loadable(lazy(() => import('views/Authentication/LoginAuth/Register')));
const ForgetPassword = Loadable(lazy(() => import('views/Authentication/LoginAuth/ForgetPassword')));
// login option 3 routing
const LoginAuth = Loadable(lazy(() => import('views/Authentication/LoginAuth/Login')));
// ==============================|| AUTHENTICATION ROUTING ||============================== //

export const newRoutes = {
  path: '/',
  element: <LoginLayout />,
  children: [
    {
      path: '/new_login',
      element: <LoginAuth />
    },

    {
      path: '/register_user',
      element: <AuthRegisterUser />
    },
    {
      path: '/register_email',
      element: <AuthRegisterEmail />
    },
    {
      path: '/reset_pwd',
      element: <ForgetPassword />
    },
    
  ]
};

